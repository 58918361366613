import { CarrierRegex } from 'Constants/CarrierRegexs'

const getCarrier = trackingNumber => {
  try {
    for (
      let carrierIndex = 0;
      carrierIndex < CarrierRegex.length;
      // eslint-disable-next-line no-plusplus
      carrierIndex++
    ) {
      const currentCarrier = CarrierRegex[carrierIndex]

      // eslint-disable-next-line no-plusplus
      for (let regexId = 0; regexId < currentCarrier.regex.length; regexId++) {
        const currentRegex = currentCarrier.regex[regexId]

        const match = trackingNumber.match(currentRegex)

        if (match !== null) {
          return [match[0], true]
        }
      }
    }

    return [trackingNumber, false]
  } catch (err) {
    console.error('getCarrier error', err)
  }
}

export default getCarrier
