import firebase from 'firebase/app'
import 'firebase/database'

class SubscriptionsManager {
  constructor() {
    // the list of DB subscriptions
    this.subscriptionRoutesList = []
  }

  /**
   * Add a subscription route to the list
   *
   * @param {String} route
   */
  addSubscriptionRoute(route) {
    this.subscriptionRoutesList.push(route)
  }

  /**
   * Unsubscribe the app from all the DB sources
   */
  async removeAllSubscriptions() {
    try {
      this.subscriptionRoutesList.forEach(route => {
        firebase
          .database()
          .ref(route)
          .off()
      })
    } catch (err) {
      console.warn(err)
    }
  }
}

export default new SubscriptionsManager()
