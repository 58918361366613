import get from 'lodash/get'
import merge from 'lodash/merge'
import pick from 'lodash/pick'
import assign from 'lodash/assign'
import isEmpty from 'lodash/isEmpty'

import axios from 'axios'

import { API_CALL } from 'Constants/ids'
import { isFunction } from 'rxjs/internal-compatibility'

export const ApiService = {
  apiCall: (
    url = '',
    endpoint = '',
    method = 'GET',
    query = {},
    headers = {},
  ) => {
    console.log({
      url,
      endpoint,
      method,
      query,
      headers,
    })

    const HTTPMethod = method.toLowerCase()

    const api = axios.create({
      baseURL: url,
      headers,
    })

    return new Promise((resolve, reject) => {
      api[HTTPMethod](endpoint, query)
        .then(data => {
          resolve(data)
        })
        .catch(error => {
          if (error.response) {
            reject(error.response.data)
          } else if (error.request) {
            reject(error.request)
          } else {
            reject(error.message)
          }
        })
    })
  },
}

const nextAction = (action, data) => {
  const next = merge({}, action, data)
  delete next[API_CALL]
  return next
}

export default () => next => action => {
  if (action.type !== API_CALL || !action.fields) return next(action)
  const {
    url,
    endpoint,
    headers,
    method,
    query,
    types,
    attachType,
    attach,
    preProcess = null,
    postProcess = null,
    callback,
  } = action.fields

  const signature = Date.now()

  const completeHeaders = assign(
    isEmpty(attach) && { 'Content-Type': 'application/json' },
    {},
    headers,
  )

  const fsaFields = pick(action.fields, 'payload', 'error', 'meta')
  const isLoadRequest =
    !method ||
    method.toUpperCase() === 'GET' ||
    method.toUpperCase() === 'PATCH' ||
    method.toUpperCase() === 'POST'

  next(
    nextAction(fsaFields, {
      type: types.REQUEST,
      meta: merge(
        { signature },
        isLoadRequest && { endpoint, isRequest: true },
      ),
    }),
  )

  const onError = error => {
    // console.log('onError =====>>>', error)
    const data = {
      payload: error,
      type: types.FAILURE,
      meta: {
        signature,
        httpCode: error.status,
        endpoint,
      },
      error: true,
    }

    next(nextAction(fsaFields, data))

    if (isFunction(callback)) {
      callback(null, data)
    }

    return data
  }

  const onSuccess = response => {
    // console.log('onSuccess =====>>>', response)
    const meta = merge(
      { signature },
      isLoadRequest && { endpoint, isSuccess: true },
    )
    let payload = get(response, 'data')

    if (isFunction(postProcess)) {
      payload = postProcess(payload)
    }

    const data = { meta, payload, type: types.SUCCESS }

    next(nextAction(fsaFields, data))

    if (isFunction(callback)) {
      callback(payload)
    }

    return data
  }

  let body = query

  if (isFunction(preProcess)) {
    body = preProcess(body)
  }

  const apiRequest = ApiService.apiCall(
    url,
    endpoint,
    method,
    body,
    completeHeaders,
    attachType,
    attach,
  )
  return apiRequest
    .then(onSuccess, onError)
    .catch(error => console.error('Request error', error))
}
