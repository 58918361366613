import React, { useState } from 'react'

import { DateRangePicker as ReactDateRangePicker } from 'react-date-range'

import {
  Button,
  Box,
  Popper,
  Paper,
  ClickAwayListener,
} from '@material-ui/core'

import moment from 'moment'

import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file

export const DateRangePickerButton = ({ startDate, endDate, onChange }) => {
  const [isPickerOpen, setIsPickerOpen] = useState(false)

  const anchorRef = React.useRef(null)

  const handleButtonPress = () => {
    setIsPickerOpen(true)
  }

  const handlePickerClose = () => {
    setIsPickerOpen(false)
  }

  const state = [
    {
      startDate,
      endDate,
      key: 'selection',
    },
  ]

  return (
    <Box>
      <Button
        ref={anchorRef}
        variant="outlined"
        color="primary"
        onClick={handleButtonPress}
      >
        {moment(startDate).format('MM/DD/YYYY')}-
        {moment(endDate).format('MM/DD/YYYY')}
      </Button>
      <Box>
        <Popper
          open={isPickerOpen}
          anchorEl={anchorRef.current}
          transition
          placement="bottom-start"
        >
          <Paper>
            <ClickAwayListener onClickAway={handlePickerClose}>
              <Box style={{ background: '#fff', zIndex: 10 }}>
                <ReactDateRangePicker
                  onChange={item => onChange(item.selection)}
                  showSelectionPreview={false}
                  months={2}
                  ranges={state}
                  direction="horizontal"
                />
              </Box>
            </ClickAwayListener>
          </Paper>
        </Popper>
      </Box>
    </Box>
  )
}
