import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/functions'

import { Observable } from 'rxjs'

import _ from 'lodash'

import i18n from 'I18N'

import SubscriptionManager from '../SubscriptionsManager'

const errorMessages = i18n.t('firebaseErrors.benchCodes')

class BenchCodesService {
  constructor() {
    this.entryCodeAddedSources = {}
    this.entryCodeRemovedSources = {}
  }

  /**
   * Clean cached sources
   */
  cleanEntryCodeSources = () => {
    this.entryCodeAddedSources = {}
    this.entryCodeRemovedSources = {}
  }

  /**
   * Add a custom message to the error object
   */
  getErrorWithMessage = error => {
    return {
      ...error,
      message: errorMessages[error.message || error.code] || error.message,
    }
  }

  /**
   * Set bench owner code
   */
  setOwnerCode = async (benchId, ownerCode) => {
    try {
      return await firebase.functions().httpsCallable('setOwnerCode')({
        benchId,
        ownerCode,
      })
    } catch (error) {
      throw this.getErrorWithMessage(error)
    }
  }

  /**
   * Create a new entry code
   */
  addEntryCode = async (benchId, entryCode, name, expiration) => {
    try {
      return await firebase.functions().httpsCallable('addEntryCode')({
        benchId,
        entryCode,
        expiration,
        name,
      })
    } catch (error) {
      throw this.getErrorWithMessage({
        ...error,
        message:
          'Please select another Entry Code value. That Code is already being used.',
      })
    }
  }

  /**
   * Subscribe on entry codes list update
   */
  getEntryCodesListForBenchId = (benchId, minDateTimestamp) => {
    return new Promise((resolve, reject) => {
      try {
        let listRef = firebase.database().ref(`entryCodes/${benchId}`)

        if (minDateTimestamp) {
          listRef = listRef.orderByChild('created').startAt(minDateTimestamp)
        }

        listRef.once(
          'value',
          listSnap => {
            const list = listSnap.val()

            const processedList = []

            _.forIn(list, (value, key) => {
              processedList.push({
                ...value,
                id: key,
              })
            })

            resolve({
              list: processedList,
              benchId,
            })
          },
          error => {
            console.warn('getPackagesListForBenchId', error)

            reject(this.getErrorWithMessage(error))
          },
        )
      } catch (error) {
        console.warn('getPackagesListForBenchId', error)

        reject(this.getErrorWithMessage(error))
      }
    })
  }

  /**
   * Get observers for entry codes list child_added and child_removed events
   */
  getEntryCodesListUpdatesForBenchId = (benchId, minDateTimestamp) => {
    return {
      onCodeAdd: this.getEntryCodesSourceForBenchId(
        benchId,
        minDateTimestamp,
        this.entryCodeAddedSources,
        'child_added',
      ),
      onCodeRemove: this.getEntryCodesSourceForBenchId(
        benchId,
        null,
        this.entryCodeRemovedSources,
        'child_removed',
      ),
    }
  }

  /**
   * Get observer for entry codes `eventType` events
   */
  getEntryCodesSourceForBenchId = (
    benchId,
    minDateTimesamp,
    sources,
    eventType,
  ) => {
    let source

    if (benchId) {
      if (sources && sources.benchId) {
        source = sources.benchId
      } else {
        const path = `entryCodes/${benchId}`
        let ref = firebase.database().ref(path)

        SubscriptionManager.addSubscriptionRoute(path)

        if (minDateTimesamp) {
          ref = ref.orderByChild('created').startAt(minDateTimesamp)
        }

        source = Observable.create(observer =>
          ref.on(eventType, dataSnapshot => {
            if (dataSnapshot) {
              observer.next(dataSnapshot)
            }
          }),
        )

        // eslint-disable-next-line no-param-reassign
        sources.benchId = source
      }
    } else {
      console.warn('No bench ID provided for entry code listening')
    }

    return source
  }

  /**
   * Subscribe on entry code value updates
   */
  getEntryCodeData = async (benchId, entryCodeId) => {
    return new Promise((resolve, reject) => {
      const ref = firebase
        .database()
        .ref(`entryCodes/${benchId}/${entryCodeId}`)

      ref.once(
        'value',
        snap => {
          if (!snap.val()) return reject()

          resolve(snap)
        },
        err => {
          console.warn('get entry code data err', err)

          reject(err)
        },
      )
    })
  }

  /**
   * Delete entry code
   */
  deleteEntryCode = async (benchId, entryCodeId) => {
    try {
      await firebase.functions().httpsCallable('deleteEntryCode')({
        benchId,
        entryCodeId,
      })
    } catch (error) {
      throw this.getErrorWithMessage(error)
    }
  }
}

export default new BenchCodesService()
