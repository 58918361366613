import { createReducer } from 'Helpers/redux'

import { USER_CHANGED } from 'Redux/actions/user'
import { AUTH_CHANGE_SUBSCRIBE } from 'Redux/actions/auth'

const initialState = {
  user: {},
}

const handlers = {
  [USER_CHANGED.SUCCESS]: (state, { payload }) => {
    if (!payload.data) return state

    return {
      ...state,
      user: {
        ...(state.user || {}),
        ...payload.data,
      },
    }
  },
  [AUTH_CHANGE_SUBSCRIBE]: (state, { payload }) => {
    if (!payload.data) return state

    return {
      ...state,
      user: {
        ...(state.user || {}),
        ...payload.data.toJSON(),
      },
    }
  },
}

export default createReducer(initialState, handlers)
