const firebaseConfig = {
  apiKey: 'AIzaSyC-IdRRhbw6pVPK0LDkdVdvPQNEF1RBT_Q',
  authDomain: 'benchsentryprod.firebaseapp.com',
  databaseURL: 'https://benchsentryprod.firebaseio.com',
  projectId: 'benchsentryprod',
  storageBucket: 'benchsentryprod.appspot.com',
  messagingSenderId: '920235217070',
  appId: '1:920235217070:web:75d355e71096ce97f79d3f',
}

export default firebaseConfig
