import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'

import { fetchPackagesArchive } from 'Redux/actions/benchCodes'

import { getPackagesArchiveArray } from 'Redux/selectors/benchCodes'

import Component from './Component'

const actions = {
  onPackagesArchiveFetch: fetchPackagesArchive,
}

const selector = createStructuredSelector({
  packagesArchive: getPackagesArchiveArray,
})

export default connect(
  selector,
  actions,
)(Component)
