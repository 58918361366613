import { createAsyncAction } from 'Helpers/redux'
import PackagesService from '../../Services/packages/PackageService'

import { firebaseCall } from './api'

export const FETCH_PACKAGES_ARCHIVE = createAsyncAction(
  'benchCodes/FETCH_PACKAGES_ARCHIVE',
)
export const fetchPackagesArchive = callback => {
  return firebaseCall({
    func: PackagesService.getPackagesArchive,
    params: [],
    types: FETCH_PACKAGES_ARCHIVE,
    callback,
  })
} 

export const FETCH_PACKAGES = createAsyncAction('benchCodes/FETCH_PACKAGES')
export const fetchPackages = callback => {
  return firebaseCall({
    func: PackagesService.getPackages,
    params: [],
    types: FETCH_PACKAGES,
    callback,
  })
}
