import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

import { Observable } from 'rxjs'

import i18n from 'I18N'

import userAccountSetupState from 'Constants/userAccountSetupState'

import SubscriptionManager from '../SubscriptionsManager'

const errorMessages = i18n.t('firebaseErrors.user')

class UserService {
  get emailVerified() {
    return this.profile.emailVerified
  }

  get profile() {
    return firebase.auth().currentUser
  }

  get uid() {
    return this.profile.uid
  }

  // Subscribe on profile updates
  getProfileUpdateListener() {
    return Observable.create(observer => {
      this._getUserRef().on('value', nextUser => {
        if (nextUser && nextUser.val()) {
          const user = firebase.auth().currentUser

          observer.next({
            ...(user ? user.toJSON() : {}),
            ...nextUser.val(),
          })
        }
      })
    })
  }

  /**
   * Returns the firebase database reference for the current user's document in
   * the users collection.
   */
  _getUserRef() {
    const path = `users/${this.uid}`

    SubscriptionManager.addSubscriptionRoute(path)

    return firebase.database().ref(path)
  }

  /**
   * Updates the basic profile provided by Firebase
   *
   * Fields include: email, password, name, phoneNumber, photoUrl
   *
   * @param profile an object containing basic profile fields to update
   * @returns {Promise<void>}
   */
  _updateProfile = profile => {
    return this.profile.updateProfile(profile)
  }

  /**
   * Updates the extended profile for the user that resides in the users
   * collection of the database
   *
   * @param profile an object containing fields to update
   * @returns {Promise<void>}
   */
  _updateExtendedProfile = profile => {
    return this.getUserRef().set(profile)
  }

  setUserSetupState = async (uid, setupState) => {
    const ref = firebase.database().ref(`users/${uid}/setupState`)

    await ref.set(setupState)
  }

  /**
   * Get user's account setup state
   */
  getUserSetupState = async uid => {
    return new Promise((resolve, reject) => {
      const ref = firebase.database().ref(`users/${uid}/setupState`)
      ref
        .once('value')
        .then(snapshot => {
          const data = snapshot.val()

          if (data) {
            resolve(data)
          }
        })
        .catch(err => reject(err))
    })
  }

  /**
   * Check user's password
   */
  checkPassword = (email, password) => {
    const checkPassword = firebase.initializeApp(
      firebase.app().options,
      'checkPassword',
    )

    return new Promise((resolve, reject) => {
      checkPassword
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          () => {
            // successful
            checkPassword.auth().signOut()
            resolve(true)
          },
          err => {
            // failed
            reject(err)
          },
        )
    })
  }

  /**
   * Get user's primary bench
   */
  getUserPrimaryBench = async () => {
    const benches = await this.getBenches()

    return Object.keys(benches)[0]
  }

  /**
   * Add a custom message to the error object
   */
  getErrorWithMessage = error => {
    return {
      ...error,
      message: errorMessages[error.code] || error.message,
    }
  }

  /**
   * Update user's display name
   */
  updateName = async name => {
    try {
      await this._updateProfile({ displayName: name })
    } catch (error) {
      console.warn('Unable to update name', error)
      throw this.getErrorWithMessage(error)
    }
  }

  /**
   * Set user's setup state to benchSetupComplete
   */
  benchSetupComplete = () => {
    const ref = firebase.database().ref(`users/${this.uid}`)

    const updatedProfile = {
      setupState: userAccountSetupState.benchSetupComplete,
    }

    return ref.update(updatedProfile)
  }

  /**
   * Finalize account
   */
  finalizeAccount = profileData => {
    const ref = firebase.database().ref(`users/${this.uid}`)

    const updatedProfile = {
      ...profileData,
      setupState: userAccountSetupState.completed,
    }

    return ref.update(updatedProfile)
  }

  /**
   * Update user's profile
   */
  updateProfile = update => {
    const ref = firebase.database().ref(`users/${this.uid}`)

    return ref.update(update)
  }

  /**
   * Update user's email
   */
  updateEmail = async (email, userPassword) => {
    try {
      await this.profile.reauthenticateWithCredential(
        firebase.auth.EmailAuthProvider.credential(
          this.profile.email,
          userPassword,
        ),
      )
      await this.profile.updateEmail(email)

      await this.profile.reauthenticateWithCredential(
        firebase.auth.EmailAuthProvider.credential(
          this.profile.email,
          userPassword,
        ),
      )
    } catch (error) {
      // console.warn('Unable to update email address', error)
      if (error.code === 'auth/email-already-in-use') {
        throw this.getErrorWithMessage({
          error,
          message:
            'Email already used. Please try another email address or log into that account', // eslint-disable-line
        })
      } else {
        throw this.getErrorWithMessage(error)
      }
    }
  }

  /**
   * Update user's address
   */
  updateAddress = async address => {
    try {
      await this.updateProfile({ address })
    } catch (error) {
      console.warn('Unable to update address', error)
      throw this.getErrorWithMessage(error)
    }
  }

  /**
   * Update user's password
   */
  updatePassword = async (prevPassword, newPassword) => {
    try {
      await this.profile.reauthenticateAndRetrieveDataWithCredential(
        firebase.auth.EmailAuthProvider.credential(
          this.profile.email,
          prevPassword,
        ),
      )
      await this.profile.updatePassword(newPassword)
    } catch (error) {
      console.warn('Unable to update password', error)
      throw this.getErrorWithMessage({
        ...error,
        message: i18n.t(
          'changePasswordScreen.validation.errorMessages.incorrectPass',
        ),
      })
    }
  }

  /**
   * Update user's phone number
   */
  updatePhoneNumber = phoneNumber => {
    return new Promise((resolve, reject) => {
      try {
        const listener = firebase.auth().verifyPhoneNumber(phoneNumber)

        listener.on('state_changed', phoneAuthCredentials => {
          if (phoneAuthCredentials.state === 'sent') {
            this.phoneVerificationId = phoneAuthCredentials.verificationId

            resolve()
          }
        })
      } catch (error) {
        console.warn('Unable to update phone number', error)
        reject(this.getErrorWithMessage(error))
      }
    })
  }

  /**
   * Apply phone number verification code
   */
  applyPhoneVerificationCode = async code => {
    try {
      await this.profile.updatePhoneNumber(
        firebase.auth.PhoneAuthProvider.credential(
          this.phoneVerificationId,
          code,
        ),
      )
    } catch (error) {
      console.warn('Unable to apply Phone Verification Code', error)

      throw this.getErrorWithMessage(error)
    }
  }

  /**
   * Fetch users's benches list
   */
  getBenches = () => {
    return new Promise((resolve, reject) => {
      firebase
        .database()
        .ref(`users/${this.uid}/benches`)
        .once(
          'value',
          snapshot => {
            resolve(snapshot.val())
          },
          err => reject(err),
        )
    })
  }

  /**
   * Update user's notifications settings
   */
  changeNotificationStatus = async (field, status) => {
    const ref = firebase
      .database()
      .ref(`users/${this.uid}/settings/notifications/${field}`)
    await ref.set(status)
  }
}

export default new UserService()
