import React, { useEffect, useState, useMemo } from 'react'

import { Typography, Box, Button } from '@material-ui/core'

import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from 'recharts'

import moment from 'moment'
import { groupBy, orderBy } from 'lodash'

import { DateRangePickerButton } from 'Components/Blocks'

const PackagesDeliveryChart = ({ onPackagesArchiveFetch, packagesArchive }) => {
  const [startDate, setStartDate] = useState(
    moment()
      .subtract({ week: 1 })
      .toDate(),
  )
  const [endDate, setEndDate] = useState(new Date())

  const fetchData = async () => {
    onPackagesArchiveFetch()
  }

  useEffect(() => {
    fetchData()
  }, [])

  const data = useMemo(() => {
    const groupedByDay = groupBy(packagesArchive, delivery =>
      moment(delivery.delivered).format('MM/DD/YYYY'),
    )

    const nextData = []
    Object.entries(groupedByDay).forEach(([date, deliveries]) => {
      const isInSelectedTimeFrame =
        moment(date).isAfter(startDate) && moment(date).isBefore(endDate)

      if (isInSelectedTimeFrame) {
        const groupedBydeliveryType = groupBy(deliveries, del =>
          del.createdBy === 'server' ? 'unknown' : 'package',
        )

        nextData.push({
          date,
          unknown: (groupedBydeliveryType.unknown || []).length,
          package: (groupedBydeliveryType.package || []).length,
        })
      }
    })

    const nextOrderedData = orderBy(nextData, node => new Date(node.date))

    return nextOrderedData
  }, [packagesArchive, startDate, endDate])

  const onDateSelectionChange = datePickerState => {
    setStartDate(datePickerState.startDate)
    setEndDate(datePickerState.endDate)
  }

  return (
    <>
      <Typography
        variant="h5"
        style={{
          marginBottom: '32px',
        }}
      >
        Packages Delivery Chart
      </Typography>
      <Box
        style={{
          marginBottom: '16px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <DateRangePickerButton
          startDate={startDate}
          endDate={endDate}
          onChange={onDateSelectionChange}
        />
        <Button variant="contained" color="secondary" onClick={fetchData}>
          Refresh Data
        </Button>
      </Box>
      <ResponsiveContainer width="100%" height="70%">
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar name="Packages" dataKey="package" stackId="f" fill="#003A70" />
          <Bar
            name="Unknown deliveries"
            dataKey="unknown"
            stackId="f"
            fill="#00A7B5"
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  )
}

export default PackagesDeliveryChart
