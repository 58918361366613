import { takeLatest } from 'redux-saga/effects'

// action types
import { AUTH_CHANGE_SUBSCRIBE, SIGN_OUT } from 'Redux/actions/auth'

// sagas
import onAuthStateChange from './onAuthChange'
import onLogoutSuccess from './onLogoutSuccess'

function* rootSaga() {
  yield takeLatest(AUTH_CHANGE_SUBSCRIBE, onAuthStateChange)
  yield takeLatest(SIGN_OUT.SUCCESS, onLogoutSuccess)
}

export default rootSaga
