import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/functions'
import 'firebase/auth'
import { Observable } from 'rxjs'

import deliveryStatus from 'Constants/deliveryStatus'
import packageStatus from 'Constants/packageStatus'

import extractTrackCode from 'Helpers/extractTrackCode'

import moment from 'moment'
import _ from 'lodash'

import i18n from 'I18N'

import EventsManager from '../events/EventsService'
import SubscriptionManager from '../SubscriptionsManager'

const errorMessages = i18n.t('firebaseErrors.bench')

class PackageService {
  constructor() {
    this.packageAddedSources = {}
    this.packageRemovedSources = {}
    this.packageArchiveAddedSources = {}
    this.packageArchiveRemovedSources = {}
  }

  /**
   * Clean cached sources
   */
  clearPackageSources = () => {
    this.packageAddedSources = {}
    this.packageRemovedSources = {}
  }

  /**
   * Add a custom message to the error object
   */
  getErrorWithMessage = error => {
    return {
      ...error,
      message: errorMessages[error.code] || error.message,
    }
  }

  /**
   * Create a new pending package
   */
  addPendingTrackingCode = (benchId, rawTrackingCode, notes, extraFields) => {
    return new Promise((resolve, reject) => {
      // console.log('addPendingTrackingCode func=>>>')
      try {
        let trackingCode = rawTrackingCode

        if (
          rawTrackingCode.startsWith('http') ||
          rawTrackingCode.startsWith('https')
        ) {
          const [extractedTrackCode] = extractTrackCode(rawTrackingCode)

          trackingCode = extractedTrackCode
        }

        const newPendingPackage = {
          isPending: true,
          created: Date.now(),
          updatedAt: Date.now(),
          notes,
          trackingCode,
          createdBy: firebase.auth().currentUser.uid,
          ...extraFields,
        }

        const benchPackagesRef = firebase.database().ref(`packages/${benchId}`)

        benchPackagesRef.push(newPendingPackage)

        resolve()
      } catch (error) {
        console.error('addPendingTrackingCode error==>>', error)

        reject(this.getErrorWithMessage(error))
      }
    })
  }

  /**
   * Fetch packages list for the bench
   */
  getPackagesListForBenchId = (benchId, minDateTimestamp) => {
    return new Promise((resolve, reject) => {
      try {
        let listRef = firebase.database().ref(`packages/${benchId}`)

        if (minDateTimestamp) {
          listRef = listRef.orderByChild('created').startAt(minDateTimestamp)
        }

        listRef.once(
          'value',
          listSnap => {
            const list = listSnap.val()
            // console.log('list', list)
            const processedList = []

            _.forIn(list, (value, key) => {
              processedList.push({
                ...value,
                packageId: key,
              })
            })

            resolve({
              list: processedList,
              benchId,
            })
          },
          error => {
            console.warn('getPackagesListForBenchId', error)

            reject(this.getErrorWithMessage(error))
          },
        )
      } catch (error) {
        console.warn('getPackagesListForBenchId', error)

        reject(this.getErrorWithMessage(error))
      }
    })
  }

  /**
   * Fetch packages archive list for the bench
   */
  getPackagesArchiveListForBenchId = (benchId, minDateTimestamp) => {
    return new Promise((resolve, reject) => {
      try {
        let listRef = firebase.database().ref(`packagesArchive/${benchId}`)

        if (minDateTimestamp) {
          listRef = listRef.orderByChild('delivered').startAt(minDateTimestamp)
        }

        listRef.once(
          'value',
          listSnap => {
            const list = listSnap.val()

            const processedList = []

            _.forIn(list, (value, key) => {
              processedList.push({
                ...value,
                packageId: key,
              })
            })

            const filteredList = processedList.filter(
              item => item.deliveryStatus !== deliveryStatus.DELETED,
            )

            resolve({
              list: filteredList,
              benchId,
            })
          },
          error => {
            console.warn('getPackagesArchiveListForBenchId', error)

            reject(this.getErrorWithMessage(error))
          },
        )
      } catch (error) {
        console.warn('getPackagesArchiveListForBenchId', error)

        reject(this.getErrorWithMessage(error))
      }
    })
  }

  /**
   * Get observers for child_added and child_removed events on packages collection
   */
  getTrackCodesListUpdatesForBenchId = (benchId, minDateTimestamp) => {
    return {
      onCodeAdd: this.getPackagesSourceForBenchId(
        benchId,
        minDateTimestamp,
        this.packageAddedSources,
        'child_added',
      ),
      onCodeRemove: this.getPackagesSourceForBenchId(
        benchId,
        null,
        this.packageRemovedSources,
        'child_removed',
      ),
    }
  }

  /**
   * Get observers for child_added and child_removed events o packagesArchive collection
   */
  getTrackCodesArchiveListUpdatesForBenchId = (benchId, minDateTimestamp) => {
    return {
      onCodeAdd: this.getPackagesArchiveSourceForBenchId(
        benchId,
        minDateTimestamp,
        this.packageArchiveAddedSources,
        'child_added',
      ),
      onCodeRemove: this.getPackagesArchiveSourceForBenchId(
        benchId,
        null,
        this.packageArchiveRemovedSources,
        'child_removed',
      ),
    }
  }

  /**
   * Update packages
   */
  updatePackage = async (benchId, packageId, update) => {
    try {
      return await firebase.functions().httpsCallable('updateTrackingCode')({
        benchId,
        packageId,
        update,
      })
    } catch (error) {
      throw this.getErrorWithMessage({
        ...error,
        message: 'Please select another Track Code value.',
      })
    }
  }

  /**
   * Subscribe on package updates
   */
  subscribePackageUpdates = (benchId, packageId) => {
    const subscriptionDate = moment()
      .subtract({ minutes: 5 })
      .valueOf()

    return Observable.create(observer => {
      const path = `packages/${benchId}/${packageId}`
      const ref = firebase.database().ref(path)

      SubscriptionManager.addSubscriptionRoute(path)

      ref.on(
        'value',
        dataSnapshot => {
          if (!dataSnapshot) return

          const val = dataSnapshot.val()

          if (
            val &&
            val.updatedAt &&
            moment(val.updatedAt).isAfter(subscriptionDate)
          ) {
            observer.next(dataSnapshot)
          }
        },
        err => observer.error(err),
      )
    })
  }

  /**
   * Unsubscribe from package updates
   */
  unsubscribePackageUpdates = (benchId, packageId) => {
    const ref = firebase.database().ref(`packages/${benchId}/${packageId}`)

    ref.off()
  }

  /**
   * Get ovserver for `eventType` events on packages collection
   */
  getPackagesSourceForBenchId = (
    benchId,
    minDateTimestamp,
    sources,
    eventType,
  ) => {
    let source

    if (benchId) {
      if (sources && sources.benchId) {
        source = sources.benchId
      } else {
        const path = `packages/${benchId}`
        let ref = firebase.database().ref(path)

        SubscriptionManager.addSubscriptionRoute(path)

        if (minDateTimestamp) {
          ref = ref.orderByChild('created').startAt(minDateTimestamp)
        }

        source = Observable.create(observer =>
          ref.on(
            eventType,
            dataSnapshot => {
              if (!dataSnapshot) return

              observer.next(dataSnapshot)
            },
            err => observer.error(err),
          ),
        )

        // eslint-disable-next-line no-param-reassign
        sources.benchId = source
      }
    } else {
      console.warn('No bench ID provided for packages listening')
    }

    return source
  }

  /**
   * Get observer for `eventType` events on packagesArchive collection
   */
  getPackagesArchiveSourceForBenchId = (
    benchId,
    minDateTimestamp,
    sources,
    eventType,
  ) => {
    let source

    if (benchId) {
      if (sources && sources.benchId) {
        source = sources.benchId
      } else {
        const path = `packagesArchive/${benchId}`
        let ref = firebase.database().ref(path)

        SubscriptionManager.addSubscriptionRoute(path)

        if (minDateTimestamp) {
          ref = ref.orderByChild('delivered').startAt(minDateTimestamp)
        }

        source = Observable.create(observer =>
          ref.on(
            eventType,
            async dataSnapshot => {
              if (!dataSnapshot) return

              const packageData = _.assign(dataSnapshot.val() || {}, {
                packageId: dataSnapshot.key,
              })

              if (eventType === 'child_added' && packageData.deliveredEventId) {
                const deliveredEvent = await EventsManager.getEventData(
                  benchId,
                  packageData.deliveredEventId,
                )

                observer.next(Object.assign(packageData, { deliveredEvent }))
              } else {
                observer.next(packageData)
              }
            },
            err => observer.error(err),
          ),
        )

        // eslint-disable-next-line no-param-reassign
        sources.benchId = source
      }
    } else {
      console.warn('No bench ID provided for packages archive listening')
    }

    return source
  }

  getPackagesArchive = async () => {
    const ref = firebase.database().ref('packagesArchive')

    const snap = await ref.once('value')

    const packagesArchiveVal = snap.val() || {}

    return packagesArchiveVal
  }

  getPackages = async () => {
    const ref = firebase.database().ref('packages')

    const snap = await ref.once('value')

    const packagesVal = snap.val() || {}

    return packagesVal
  }

  /**
   * Delete package
   */
  deletePackage = async (benchId, packageId, status) => {
    try {
      await firebase.functions().httpsCallable('deletePackage')({
        benchId,
        packageId,
        status,
      })
    } catch (error) {
      if (error.code !== 'out-of-range') {
        throw this.getErrorWithMessage(error)
      }
    }
  }

  /**
   * Delete archive package
   */
  deleteArchivedPackage = async (benchId, packageId) => {
    try {
      const packageUpdate = {
        status: packageStatus.deleted,
      }

      const dbRef = firebase
        .database()
        .ref(`packagesArchive/${benchId}/${packageId}`)

      await dbRef.update(packageUpdate)

      return {
        benchId,
        packageId,
      }
    } catch (error) {
      console.log(error)
      // throw this.getErrorWithMessage(error)
    }
  }

  /**
   * Fetch package data
   */
  getPackageData = (benchId, packageId, source = 'packages') => {
    return new Promise((resolve, reject) => {
      const ref = firebase.database().ref(`${source}/${benchId}/${packageId}`)

      ref.once(
        'value',
        snap => {
          if (!snap.val() && source === 'packages') {
            // if not in packages try to search in packagesArchive
            resolve(this.getPackageData(benchId, packageId, 'packagesArchive'))
          } else {
            resolve(snap)
          }
        },
        err => {
          console.warn('get package data err', err)

          reject(err)
        },
      )
    })
  }
}

export default new PackageService()
