import { createSelector } from 'reselect'

const userData = store => store.user

export const getUser = createSelector(
  userData,
  data => data.user,
)
export const getUid = createSelector(
  userData,
  data => data.user.uid,
)
export const getNotificationSettings = createSelector(
  userData,
  data => data.user.settings ? data.user.settings.notifications : [],
)
