import * as Yup from 'yup'
import i18n from 'I18N'

const errorMessages = i18n.t('loginScreen.resetPasswordModal.errorMessages')

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email(errorMessages.email.valid)
    .required(errorMessages.email.required),
})

export { validationSchema }
