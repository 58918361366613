import _ from 'lodash'
import { toast } from 'react-toastify'

import { FIREBASE_CALL } from 'Constants/ids'
import { APP_ENV } from 'Config/App'

import { setRequestStatus } from 'Redux/actions/common'

const __DEV__ = APP_ENV === 'development'

export default store => next => action => {
  if (action.type !== FIREBASE_CALL || !action.fields) return next(action)

  const { func, params, types, meta, callback } = action.fields

  next({
    type: types.REQUEST,
  })
  if (meta && meta.withActivityLoader) {
    store.dispatch(setRequestStatus(true, meta.spinnerOptions))
  }

  func(...(params || []))
    .then(data => {
      // eslint-disable-next-line no-undef
      if (__DEV__) {
        // eslint-disable-next-line no-console
        console.log('firebase dispatch', types.SUCCESS, data)
      }

      if (_.isFunction(callback)) callback(true, data)

      if (meta && meta.withActivityLoader) {
        store.dispatch(setRequestStatus(false))
      }

      next({
        type: types.SUCCESS,
        payload: {
          data,
        },
        meta,
      })
    })
    .catch(error => {
      // eslint-disable-next-line no-undef
      if (__DEV__) {
        // eslint-disable-next-line no-console
        console.log(types.FAILURE, error)
      }

      if (meta && meta.withActivityLoader) {
        store.dispatch(setRequestStatus(false))
      }

      if (_.isFunction(callback)) callback(false, error)

      if (error.code !== 'PERMISSION_DENIED') {
        toast.error(error.message)
      }

      next({
        type: types.FAILURE,
        payload: {
          error,
        },
        meta,
      })
    })
}
