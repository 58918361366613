import { createMuiTheme } from '@material-ui/core/styles'

export default createMuiTheme({
  palette: {
    primary: {
      main: '#003A70',
    },
    secondary: {
      main: '#00A7B5',
    },
    error: {
      light: '#FFC8C8',
      main: '#FFA4A4',
      dark: '#E4292F',
    },
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },

  typography: {
    h1: {
      // fontSize: "1.75rem",
      // fontWeight: "400"
    },
    h2: {
      // fontSize: "1.5rem",
    },
    h3: {
      // fontSize: "1.25rem",
    },
    h4: {
      // fontSize: "1.125rem",
    },
    h5: {
      // fontSize: "0.875rem",
    },
    h6: {
      // fontSize: "0.8125rem",
      // fontWeight: "400"
    },
    subtitle1: {
      // fontSize: "1rem",
    },
    subtitle2: {
      // fontSize: "0.75rem",
      // fontWeight: "400"
    },
    body1: {
      // fontSize: "1rem",
    },
    body2: {
      // fontSize: "0.8125rem",
    },
    buttontext: {
      // fontSize: "1rem",
    },
    caption: {
      // fontSize: "0.625rem",
    },
    overline: {
      // color: "rgb(115, 129, 143)",
      // fontWeight: "bold",
      // fontSize: "0.625rem",
    },
  },

  overrides: {
    MuiAppBar: {
      root: {
        background: 'white !important',
        color: 'black !important',
        borderTopWidth: 0,
        borderRightWidth: 0,
        borderLeftWidth: 0,
        borderBottomWidth: 1,
        borderColor: 'rgb(200, 206, 211)',
        borderStyle: 'solid',
        boxShadow: 'rgba(0, 0, 0, 0.075) 0px 0.125rem 0.25rem !important',
      },
    },
    MuiButton: {
      contained: {
        boxShadow: '0 2px 9px 0 rgba(0,0,0,0.19)',
        borderRadius: 4,
        fontSize: 16,
        fontWeight: 400,
        textTransform: 'inherit',
      },
      containedSecondary: {
        color: 'white',
      },
      sizeSmall: {
        fontSize: 14,
        padding: '4px 10px !important',
      },
    },
    MuiCard: {
      root: {
        boxShadow: 'none !important',
        border: '1px solid rgb(200, 206, 211)',
      },
    },
    MuiOutlinedInput: {
      adornedEnd: {
        paddingRight: 0,
      },
    },
    MuiDialogTitle: {
      root: {
        padding: 16,
      },
    },
    MuiDialogActions: {
      root: {
        padding: 16,
      },
    },
  },
})
