/* eslint-disable max-len */
const Carrier = {
  UPS: 'ups',
  FEDEX: 'fedex',
  USPS: 'usps',
  AMAZON: 'amazon',
  DHL: 'dhl',
  UNKNOWN: 'unknown',
}
// old UPS regex
// /\\b(1Z ?[0-9A-Z]{3} ?[0-9A-Z]{3} ?[0-9A-Z]{2} ?[0-9A-Z]{4} ?[0-9A-Z]{3} ?[0-9A-Z]|[\\dT]\\d\\d\\d ?\\d\\d\\d\\d ?\\d\\d\\d)\\b/
export const CarrierRegex = [
  {
    regex: [
      /(\\b\\d{30}\\b)|(\\b91\\d+\\b)|(\\b\\d{20}\\b)/,
      /(E\\D{1}\\d{9}\\D{2})|(9\\d{15,21})/,
      /(91\d{18,20})/,
      /(92\d{18,20})/,
      /(93\d{18,20})/,
      /(94\d{18,20})/,
      /(95\d{18,20})/,
      /([A-Za-z]{2}[0-9]+US)/,
    ],
    carrier: Carrier.USPS,
  },
  {
    regex: [
      /\b(1Z)?[0-9A-Z]{3} ?[0-9A-Z]{3} ?[0-9A-Z]{2} ?[0-9A-Z]{4} ?[0-9A-Z]{3} ?[0-9A-Z]|[\\dT]\\d\\d\\d ?\\d\\d\\d\\d ?\\d\\d\\d/,
    ],
    carrier: Carrier.UPS,
  },
  {
    regex: [/(TBA\d{12})/],
    carrier: Carrier.AMAZON,
  },
  {
    regex: [
      /(\\b96\\d{20}\\b)|(\\b\\d{15}\\b)|(\\b\\d{12}\\b)/,
      /\\b((98\\d\\d\\d\\d\\d?\\d\\d\\d\\d|98\\d\\d) ?\\d\\d\\d\\d ?\\d\\d\\d\\d( ?\\d\\d\\d)?)\\b/,
      /(\d{12,19})/,
    ],
    carrier: Carrier.FEDEX,
  },
  {
    regex: [/(\d{10,11})/],
    carrier: Carrier.DHL,
  },
]

export default Carrier
