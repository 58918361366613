import React from 'react'

import Grid from '@material-ui/core/Grid'

import icon from 'Assets/Icons/BenchSentry_logo_whiteonblue_rgb.svg'

const SplashScreen = () => {
  return (
    <Grid
      container
      display="flex"
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: '100vh', backgroundColor: '#003A70' }}
    >
      <Grid item xs={8} md={4} lg={3} xl={2}>
        <img
          alt=""
          src={icon}
          style={{
            height: '27vh',
            width: '42vh',
            userSelect: 'none',
          }}
        />
      </Grid>
    </Grid>
  )
}

export default SplashScreen
