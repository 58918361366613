export const CLEAR_ERROR = 'common/CLEAR_ERROR'
export const clearError = () => {
  return {
    type: CLEAR_ERROR,
  }
}

export const SET_REQUEST_STATUS = 'common/SET_REQUEST_STATUS'
export const setRequestStatus = (status, spinnerOptions) => {
  return {
    type: SET_REQUEST_STATUS,
    payload: {
      data: status,
      spinnerOptions,
    },
  }
}

export const SET_INTERNET_CONNECTION_STATUS =
  'common/SET_INTERNET_CONNECTION_STATUS'
export const setInternetConnectionStatus = status => {
  return {
    type: SET_INTERNET_CONNECTION_STATUS,
    payload: {
      data: status,
    },
  }
}

export const REMOVE_OFFLINE_ENTRY_CODES = 'common/REMOVE_OFFLINE_ENTRY_CODES'

export const cleanOfflineEntryCodes = dispatch => {
  dispatch({
    type: REMOVE_OFFLINE_ENTRY_CODES,
    payload: {},
  })
}

export const CLOSE_ALERT = 'common/CLOSE_ALERT'
export const closeAlert = () => {
  return {
    type: CLOSE_ALERT,
  }
}
export const CLOSE_SETTINGS_ALERT = 'common/CLOSE_SETTINGS_ALERT'
export const closeSettingsAlert = () => {
  return {
    type: CLOSE_SETTINGS_ALERT,
  }
}
