import { createStructuredSelector } from 'reselect'
import { connect } from 'react-redux'

import { fetchPackagesArchive, fetchPackages } from 'Redux/actions/benchCodes'

import {
  getPackagesArchiveArray,
  getPackagesArray,
} from 'Redux/selectors/benchCodes'

import Component from './Component'

const actions = {
  onPackagesArchiveFetch: fetchPackagesArchive,
  onPackagesFetch: fetchPackages,
}

const selector = createStructuredSelector({
  packagesArchive: getPackagesArchiveArray,
  packages: getPackagesArray,
})

export default connect(
  selector,
  actions,
)(Component)
