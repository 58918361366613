import { createReducer } from 'Helpers/redux'

import {
  FETCH_PACKAGES,
  FETCH_PACKAGES_ARCHIVE,
} from 'Redux/actions/benchCodes'

const initialState = {
  packages: {},
  packagesArchive: {},
}

const handlers = {
  [FETCH_PACKAGES_ARCHIVE.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      packagesArchive: payload.data,
    }
  },
  [FETCH_PACKAGES.SUCCESS]: (state, { payload }) => {
    return {
      ...state,
      packages: payload.data,
    }
  },
}

export default createReducer(initialState, handlers)
