import { createSelector } from 'reselect'

import _ from 'lodash'

const getBenchCodesData = store => store.benchCodes

export const getPackagesArchiveArray = createSelector(
  getBenchCodesData,
  data => {
    const packages = _.flatten(
      Object.entries(data.packagesArchive).map(([benchId, packagesObject]) => {
        return Object.entries(packagesObject).map(([packageId, delivery]) => ({
          ...delivery,
          packageId,
          benchId,
        }))
      }),
    )

    return packages
  },
)

export const getPackagesArray = createSelector(
  getBenchCodesData,
  data => {
    const packages = _.flatten(
      Object.entries(data.packages).map(([benchId, packagesObject]) => {
        return Object.entries(packagesObject).map(([packageId, delivery]) => ({
          ...delivery,
          packageId,
          benchId,
        }))
      }),
    )

    return packages
  },
)
