import firebase from 'firebase/app'
import 'firebase/database'

import { Observable } from 'rxjs/Observable'

import _ from 'lodash'

import i18n from 'I18N'

import SubscriptionManager from '../SubscriptionsManager'

const errorMessages = i18n.t('firebaseErrors.events')

class EventsService {
  constructor() {
    this.eventAddedSources = {}
    this.eventRemovedSources = {}
    this.prevItemsAmount = 0
  }

  /**
   * Clean cached sources
   */
  clearEventSources = () => {
    this.eventAddedSources = {}
    this.eventRemovedSources = {}
  }

  /**
   * Add a custom message to the error object
   */
  getErrorWithMessage = error => {
    return {
      ...error,
      message: errorMessages[error.code] || error.message,
    }
  }

  /**
   * Fetch next `itemsAmount` events from the  userEvents collection
   * NOTE: database method limitToLast works incorrect
   */
  getNextEventsAmountForBench = (benchId, itemsAmount) => {
    return new Promise((resolve, reject) => {
      try {
        const listRef = firebase
          .database()
          .ref(`userEvents/${benchId}`)
          .orderByChild('eventTime')
        // .limitToLast(this.prevItemsAmount + itemsAmount)

        const callback = listSnap => {
          listRef.off('value', callback)

          const list = listSnap.val()

          const processedList = []

          _.forIn(list, (value, key) => {
            processedList.push({
              ...value,
              id: key,
            })
          })

          this.prevItemsAmount += itemsAmount || 0

          resolve({
            list: processedList,
            isListEnd: processedList.length < this.prevItemsAmount,
            benchId,
          })
        }

        listRef.once('value', callback, error => {
          console.warn('getEventsListForBenchId', error)

          reject(this.getErrorWithMessage(error))
        })
      } catch (error) {
        console.warn('getEventsListForBenchId', error)

        reject(this.getErrorWithMessage(error))
      }
    })
  }

  /**
   * Fetch user events list
   */
  getEventsListForBenchId = (benchId, minDateTimestamp) => {
    return new Promise((resolve, reject) => {
      try {
        let listRef = firebase
          .database()
          .ref(`userEvents/${benchId}`)
          .orderByChild('eventTime')

        if (minDateTimestamp) {
          listRef = listRef.startAt(minDateTimestamp - 1)
        }

        listRef.once(
          'value',
          listSnap => {
            const list = listSnap.val()

            const processedList = []

            _.forIn(list, (value, key) => {
              processedList.push({
                ...value,
                id: key,
              })
            })

            resolve({
              list: processedList,
              benchId,
            })
          },
          error => {
            console.warn('getEventsListForBenchId', error)

            reject(this.getErrorWithMessage(error))
          },
        )
      } catch (error) {
        console.warn('getEventsListForBenchId', error)

        reject(this.getErrorWithMessage(error))
      }
    })
  }

  /**
   * Get observers for child_added and child_removed events on userEvents collection
   */
  getEventsUpdatesForBench = (benchId, minDateTimestamp) => {
    return {
      eventAdded: this.getEventsSourceForBenchId(
        benchId,
        minDateTimestamp,
        this.eventAddedSources,
        'child_added',
      ),
      eventRemoved: this.getEventsSourceForBenchId(
        benchId,
        null,
        this.eventRemovedSources,
        'child_removed',
      ),
    }
  }

  /**
   * Get observer for `eventType` events on userEvents collection
   */
  getEventsSourceForBenchId = (
    benchId,
    minDateTimestamp,
    sources,
    eventType,
  ) => {
    let eventsSource

    if (benchId) {
      // Check if this source has already been created
      if (sources && sources.benchId) {
        // Source found, return existing observable
        eventsSource = sources.benchId
      } else {
        const path = `userEvents/${benchId}`
        let eventsRef = firebase.database().ref(path)

        SubscriptionManager.addSubscriptionRoute(path)

        if (minDateTimestamp) {
          eventsRef = eventsRef
            .orderByChild('eventTime')
            .startAt(minDateTimestamp)
        }

        eventsSource = Observable.create(observer =>
          eventsRef.on(
            eventType,
            dataSnapshot => {
              if (!dataSnapshot) return

              const eventPath = `userEvents/${benchId}/${dataSnapshot.key}`
              const currEventRef = firebase.database().ref(eventPath)
              SubscriptionManager.addSubscriptionRoute(eventPath)

              // subscription on event updates
              currEventRef.on('value', eventSnap => {
                if (!eventSnap) return

                observer.next(eventSnap)
              })
            },
            err => observer.error(err),
          ),
        )

        // eslint-disable-next-line no-param-reassign
        sources.benchId = eventsSource
      }
    } else {
      console.warn('No bench ID provided for event listening')
    }

    return eventsSource
  }

  /**
   * Get event data from DB
   */
  getEventData = (benchId, eventId) => {
    return new Promise((resolve, reject) => {
      const ref = firebase.database().ref(`events/${benchId}/${eventId}`)

      ref.once(
        'value',
        snap => {
          if (!snap) {
            resolve(null)

            return
          }

          const val = snap.val()

          if (!val) {
            resolve(null)

            return
          }

          resolve(Object.assign(val, { id: snap.key }))
        },
        err => {
          console.warn('getEventData err', err)

          reject(err)
        },
      )
    })
  }

  /**
   * Delete an item from userEvents collection
   */
  deleteUserEvent = (benchId, eventId) => {
    return new Promise(resolve => {
      const ref = firebase.database().ref(`userEvents/${benchId}/${eventId}`)

      ref.remove(() => {
        resolve()
      })
    })
  }
}

export default new EventsService()
