import { createAsyncAction } from 'Helpers/redux'
import UserService from 'Services/user/UserService'

import { firebaseCall } from './api'

export const USER_CHANGED = createAsyncAction('user/CHANGED')
export const subscribeUserChanged = () => dispatch => {
  UserService.getProfileUpdateListener().subscribe(
    nextUser =>
      dispatch({
        type: USER_CHANGED.SUCCESS,
        payload: {
          data: nextUser,
        },
      }),
    err => {
      console.error(USER_CHANGED.FAILURE, err)
      dispatch({
        type: USER_CHANGED.FAILURE,
        payload: {
          data: err,
        },
      })
    },
  )
}

export const UPDATE_NAME = createAsyncAction('user/UPDATE_NAME')
export const updateUserName = (nextName, callback) => {
  return firebaseCall({
    func: UserService.updateName,
    params: [nextName],
    types: UPDATE_NAME,
    callback,
  })
}

export const UPDATE_EMAIL = createAsyncAction('user/UPDATE_EMAIL')
export const updateUserEmail = (nextEmail, password, callback) => {
  return firebaseCall({
    func: UserService.updateEmail,
    params: [nextEmail, password],
    types: UPDATE_EMAIL,
    callback,
  })
}

export const UPDATE_ADDRESS = createAsyncAction('user/UPDATE_ADDRESS')
export const updateUserAddress = (nextAddress, callback) => {
  return firebaseCall({
    func: UserService.updateAddress,
    params: [nextAddress],
    types: UPDATE_ADDRESS,
    callback,
  })
}

export const UPDATE_PASSWORD = createAsyncAction('user/UPDATE_PASSWORD')
export const updateUserPassword = (prevPassword, nextPassword, callback) => {
  return firebaseCall({
    func: UserService.updatePassword,
    params: [prevPassword, nextPassword],
    types: UPDATE_PASSWORD,
    callback,
  })
}

export const UPDATE_PHONE = createAsyncAction('user/UPDATE_PHONE')
export const updateUserPhoneNumber = (nextNumber, callback) => {
  return firebaseCall({
    func: UserService.updatePhoneNumber,
    params: [nextNumber],
    types: UPDATE_PHONE,
    callback,
  })
}

export const APPLY_PHONE_VERIFICATION_CODE = createAsyncAction(
  'user/APPLY_PHONE_VERIFICATION_CODE',
)
export const applyPhoneVerificationCode = (code, callback) => {
  return firebaseCall({
    func: UserService.applyPhoneVerificationCode,
    params: [code],
    types: APPLY_PHONE_VERIFICATION_CODE,
    callback,
  })
}
export const CHECK_PASSWORD = createAsyncAction('user/CHECK_PASSWORD')
export const checkUserPassword = ({ email, password }, callback) => {
  return firebaseCall({
    func: UserService.checkPassword,
    params: [email, password],
    types: CHECK_PASSWORD,
    callback,
  })
}
export const CHANGE_NOTIFICATION_STATUS = createAsyncAction(
  'user/CHANGE_NOTIFICATION_STATUS',
)
export const changeNotificationStatus = ({ field, status }, callback) => {
  return firebaseCall({
    func: UserService.changeNotificationStatus,
    params: [field, status],
    types: CHANGE_NOTIFICATION_STATUS,
    callback,
  })
}

export const UPDATE_PROFILE = createAsyncAction('user/UPDATE_PROFILE')
export const updateUserProfile = (data, callback) => {
  return firebaseCall({
    func: UserService.updateProfile,
    params: [data],
    types: UPDATE_ADDRESS,
    callback,
  })
}
