import React, { useEffect, useState, useMemo } from 'react'

import { Typography, Box, Button } from '@material-ui/core'

import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from 'recharts'

import moment from 'moment'
import { groupBy, orderBy } from 'lodash'

import { DateRangePickerButton } from 'Components/Blocks'

const PackagesDeliveryChart = ({
  onPackagesArchiveFetch,
  onPackagesFetch,
  packagesArchive,
  packages,
}) => {
  const [startDate, setStartDate] = useState(
    moment()
      .subtract({ week: 1 })
      .toDate(),
  )
  const [endDate, setEndDate] = useState(new Date())

  const fetchData = async () => {
    onPackagesArchiveFetch()
    onPackagesFetch()
  }

  useEffect(() => {
    fetchData()
  }, [])

  const data = useMemo(() => {
    console.groupCollapsed('generate data')
    const allTrackCodes = [...packages, ...packagesArchive]

    console.log('allTrackCodes', allTrackCodes)

    const groupedByDay = groupBy(
      allTrackCodes,
      delivery =>
        delivery.createdBy !== 'server' &&
        moment(delivery.created).format('MM/DD/YYYY'),
    )

    console.log('groupedByDay', groupedByDay)

    console.log({
      startDate,
      endDate,
    })

    const nextData = []
    Object.entries(groupedByDay).forEach(([date, deliveries]) => {
      const isInSelectedTimeFrame =
        moment(date).isAfter(startDate) && moment(date).isBefore(endDate)

      console.log(date, isInSelectedTimeFrame)

      if (isInSelectedTimeFrame) {
        nextData.push({
          date,
          created: (deliveries || []).length,
        })
      }
    })

    console.log('nextData', nextData)

    const nextOrderedData = orderBy(nextData, node => new Date(node.date))
    console.groupEnd()
    return nextOrderedData
  }, [packagesArchive, packages, startDate, endDate])

  const onDateSelectionChange = datePickerState => {
    setStartDate(datePickerState.startDate)
    setEndDate(datePickerState.endDate)
  }

  return (
    <>
      <Typography
        variant="h5"
        style={{
          marginBottom: '32px',
        }}
      >
        Track Codes Chart
      </Typography>
      <Box
        style={{
          marginBottom: '16px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <DateRangePickerButton
          startDate={startDate}
          endDate={endDate}
          onChange={onDateSelectionChange}
        />
        <Button variant="contained" color="secondary" onClick={fetchData}>
          Refresh Data
        </Button>
      </Box>
      <ResponsiveContainer width="100%" height="70%">
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar name="Track Codes Created" dataKey="created" fill="#003A70" />
        </BarChart>
      </ResponsiveContainer>
    </>
  )
}

export default PackagesDeliveryChart
