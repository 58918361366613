import React, { useState } from 'react'
import { Formik } from 'formik'
import { toast } from 'react-toastify'

import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import i18n from 'I18N'

import { ForgotPasswordModal } from './innerBlocks'

import { validationSchema } from './config'

import { useStyles } from './styles'

function LoginPage(props) {
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(
    false,
  )

  const classes = useStyles()

  const handleLoginButtonClick = values => {
    const { onSignIn } = props

    onSignIn(values.email, values.password)
  }

  const handleForgotPasswordBtnClick = () => {
    setIsForgotPasswordModalOpen(true)
  }

  const handleForgotPasswordModalClose = () => {
    setIsForgotPasswordModalOpen(false)
  }

  const handleForgotPasswordModalConfirm = email => {
    const { onSendResetPasswordCode } = props

    onSendResetPasswordCode(email, null, isSuccess => {
      if (isSuccess) {
        setIsForgotPasswordModalOpen(false)

        toast.success(i18n.t('loginScreen.resetPasswordModal.result.success'))
      }
    })
  }

  return (
    <>
      <img alt="" src="logo.svg" className={classes.logo} />

      <Typography align="center" variant="h5" className={classes.title}>
        {i18n.t('loginScreen.headerText')}
      </Typography>
      <Formik
        initialValues={{
          email: '',
          password: '',
        }}
        validationSchema={validationSchema}
        onSubmit={handleLoginButtonClick}
      >
        {({
          values = {},
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          const handleInputKeyDown = e => {
            if (e.key === 'Enter') {
              handleSubmit()
            }
          }

          return (
            <>
              <TextField
                autoFocus
                error={errors.email && touched.email}
                margin="dense"
                id="email"
                helperText={errors.email}
                name="email"
                label={i18n.t('loginScreen.emailInputLabel')}
                type="text"
                value={values.email}
                variant="outlined"
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                onKeyDown={handleInputKeyDown}
              />
              <TextField
                error={errors.password && touched.password}
                margin="dense"
                id="password"
                helperText={errors.password}
                name="password"
                label={i18n.t('loginScreen.passwordInputLabel')}
                type="password"
                value={values.password}
                variant="outlined"
                fullWidth
                onBlur={handleBlur}
                onChange={handleChange}
                onKeyDown={handleInputKeyDown}
              />

              <Button
                fullWidth
                color="secondary"
                variant="contained"
                className={classes.button}
                onClick={handleSubmit}
              >
                {i18n.t('loginScreen.loginBtnLabel')}
              </Button>
            </>
          )
        }}
      </Formik>
      <Button color="primary" onClick={handleForgotPasswordBtnClick}>
        {i18n.t('loginScreen.forgotPasswordBtnLabel')}
      </Button>
      <ForgotPasswordModal
        isOpen={isForgotPasswordModalOpen}
        onConfirm={handleForgotPasswordModalConfirm}
        onClose={handleForgotPasswordModalClose}
      />
    </>
  )
}

export default LoginPage
