import { createReducer } from 'Helpers/redux'

import {
  AUTH_CHANGE_SUBSCRIBE,
  REGISTER_WITH_EMAIL_AND_PASSWORD,
  SET_CURRENT_REGISTER_STEP,
} from 'Redux/actions/auth'

const initialState = {
  isAuthenticate: false,

  // used to continue registration flow after app being closed
  currentRegisterStep: null,
}

const handlers = {
  [AUTH_CHANGE_SUBSCRIBE]: (state, { payload }) => {
    return {
      ...state,
      isAuthenticate: !!payload.data,
    }
  },
  [REGISTER_WITH_EMAIL_AND_PASSWORD.REQUEST]: state => {
    return {
      ...state,
    }
  },
  [SET_CURRENT_REGISTER_STEP]: (state, { payload }) => {
    return {
      ...state,
      currentRegisterStep: payload.data,
    }
  },
}

export default createReducer(initialState, handlers)
