import firebase from 'firebase/app'
import 'firebase/auth'

import { Observable } from 'rxjs/Observable'
import { debounce } from 'lodash'

import i18n from 'I18N'

import UserAccountSetupState from 'Constants/userAccountSetupState'

import SubscriptionManager from '../SubscriptionsManager'
import EventService from '../events/EventsService'
import PackageService from '../packages/PackageService'
import BenchCodesService from '../codes/BenchCodesService'
import UserService from '../user/UserService'

const errorMessages = i18n.t('firebaseErrors.auth')

class AuthService {
  constructor() {
    const handleUserIsNotAdmin = debounce(observer => {
      observer.next({
        user: null,
        error: this.getErrorWithMessage({
          code: 'auth/not-an-admin',
        }),
      })

      firebase.auth().signOut()
    }, 1000)

    this.loggedInSource = Observable.create(observer => {
      const handleUserStateChange = user => {
        if (!user) {
          observer.next({ user })

          return
        }

        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then(idTokenResult => {
            // Confirm the user is an Admin.
            if (idTokenResult.claims.admin) {
              observer.next({ user })
            } else {
              handleUserIsNotAdmin(observer)
            }
          })
          .catch(error => {
            observer.error(this.getErrorWithMessage(error))
          })
      }

      firebase.auth().onAuthStateChanged(handleUserStateChange)
      firebase.auth().onIdTokenChanged(handleUserStateChange)
    })
  }

  get isLoggedIn() {
    return this.loggedInSource
  }

  /**
   * Add a custom message to the error object
   */
  getErrorWithMessage = error => {
    return {
      ...error,
      message: errorMessages[error.code] || error.message,
    }
  }

  /**
   * Create a preorder user
   */
  createPreOrderUser = async (email, password) => {
    try {
      const data = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)

      await UserService.setUserSetupState(
        data.user.uid,
        UserAccountSetupState.preOrder,
      )

      return data
    } catch (error) {
      throw this.getErrorWithMessage(error)
    }
  }

  createUserWithEmailAndPassword = async (email, password) => {
    try {
      const data = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)

      return data
    } catch (error) {
      throw this.getErrorWithMessage(error)
    }
  }

  signInWithEmailAndPassword = async (email, password) => {
    try {
      const data = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password)

      return data
    } catch (error) {
      throw this.getErrorWithMessage(error)
    }
  }

  signOut = async () => {
    try {
      SubscriptionManager.removeAllSubscriptions()

      await firebase.auth().signOut()

      // clean up cached sources
      EventService.clearEventSources()
      PackageService.clearPackageSources()
      BenchCodesService.cleanEntryCodeSources()
    } catch (error) {
      throw this.getErrorWithMessage(error)
    }
  }

  sendPasswordResetEmail = async (email, settings) => {
    try {
      await firebase.auth().sendPasswordResetEmail(email, settings)
    } catch (error) {
      console.warn('sendPasswordResetEmail error', error)

      throw this.getErrorWithMessage(error)
    }
  }

  confirmResetPassword = async (confirmCode, newPassword) => {
    try {
      await firebase.auth().confirmPasswordReset(confirmCode, newPassword)
    } catch (error) {
      console.warn('confirmResetPassword error', error)

      throw this.getErrorWithMessage(error)
    }
  }

  sendSignInLinkToEmail = async (email, settings) => {
    try {
      await firebase.auth().sendSignInLinkToEmail(email, settings)
    } catch (error) {
      console.warn('sendSignInLinkToEmail error', error)

      throw this.getErrorWithMessage(error)
    }
  }

  signInWithEmailLink = async (email, link) => {
    try {
      await firebase.auth().signInWithEmailLink(email, link)
    } catch (error) {
      console.warn('signInWithEmailLink error', error)

      throw this.getErrorWithMessage(error)
    }
  }
}

export default new AuthService()
