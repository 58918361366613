import { createStore, applyMiddleware } from 'redux'

import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/es/storage'
import thunk from 'redux-thunk'

import { composeWithDevTools } from 'redux-devtools-extension'

// saga middleware
import createSagaMiddleware from 'redux-saga'
import runSagas from './middleware/sagas'

// api middleware
import apiMiddleware from './middleware/api'
import firebaseMiddleware from './middleware/firebase'

import rootReducer from './reducers'

export const configureStore = () => {
  const sagaMiddleware = createSagaMiddleware()

  const middlewares = [firebaseMiddleware, sagaMiddleware, apiMiddleware, thunk]

  const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['common'],
  }

  const enhancers = applyMiddleware(...middlewares)

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const store = createStore(persistedReducer, composeWithDevTools(enhancers))

  runSagas(sagaMiddleware)

  return store
}

export const store = configureStore()

export const rehydrateStore = () => {
  return new Promise(resolve => {
    persistStore(store, null, () => {
      resolve()
    })

    // persistor.purge()
  })
}
