import { createSelector } from 'reselect'

const authData = store => store.auth

export const getCurrentRegisterStep = createSelector(
  authData,
  data => data.currentRegisterStep,
)

export const getUserAuthenticated = createSelector(
  authData,
  data => data.isAuthenticate,
)
