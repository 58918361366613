import { API_CALL, FIREBASE_CALL } from '../../Constants/ids'

export const apiCall = fields => ({
  type: API_CALL,
  fields,
})

export const firebaseCall = fields => ({
  type: FIREBASE_CALL,
  fields,
})

export default {}
