import { createReducer } from 'Helpers/redux'

import {
  CLEAR_ERROR,
  SET_REQUEST_STATUS,
  CLOSE_ALERT,
  CLOSE_SETTINGS_ALERT,
} from 'Redux/actions/common'

const initialState = {
  isRequest: false,
  spinnerOptions: null,
  alertStatus: {
    isAlertClose: true,
    isAlertSettingsClose: true,
  },
}

const handlers = {
  [CLEAR_ERROR]: state => {
    return {
      ...state,
      errorMessage: null,
    }
  },
  [SET_REQUEST_STATUS]: (state, { payload }) => {
    return {
      ...state,
      isRequest: payload.data,
      spinnerOptions: payload.spinnerOptions || null,
    }
  },
  [CLOSE_ALERT]: state => {
    return {
      ...state,
      alertStatus: {
        ...state.alertStatus,
        isAlertClose: true,
      },
    }
  },
  [CLOSE_SETTINGS_ALERT]: state => {
    return {
      ...state,
      alertStatus: {
        ...state.alertStatus,
        isAlertSettingsClose: true,
      },
    }
  },
}

export default createReducer(initialState, handlers)
