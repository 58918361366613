// eslint-disable-next-line
import { store } from 'Redux'

function subscribe() {
  try {
  } catch (err) {
    console.error(err)
  }
}

export default subscribe
