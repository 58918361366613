import { put } from 'redux-saga/effects'

import { subscribeUserChanged } from 'Redux/actions/user'
import subscribeUpdates from './subscribeUpdates'

function* onAuthStateChange(action) {
  try {
    const isAuth = !!(action.payload.data && action.payload.data.uid)

    if (isAuth) {
      yield put(subscribeUserChanged())

      const { uid } = action.payload.data

      // subscribe on user's benches list updates
      subscribeUpdates(uid)
    }

    yield true
  } catch (err) {
    console.error(err)
  }
}

export default onAuthStateChange
