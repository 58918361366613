import { makeStyles } from '@material-ui/core/styles'

export const drawerWidth = 320

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
    },
  },
  menuButton: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  drawerContainer: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#003A70 !important',
    color: 'white',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    minHeight: '100vh'
  },
  toolbar: theme.mixins.toolbar,
  logoContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    width: '100%',
    height: 110,
    margin: '0 0 16px 0',
    padding: '40px 0 24px 48px',
  },
  logo_full: {
    width: 130,
  },
  smallLogoContainer: {
    height: 25,
    marginRight: 8,
  },
  logo: {
    width: 25,
  },
  listContainer: {
    paddingLeft: 32,
  },
  logoutContainer: {
    paddingLeft: 32,
    display: 'flex',
    flexGrow: 1,
    alignItems: 'flex-end',
    height: '100%',
  },
}))
