import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { signIn, sendResetPasswordCode } from 'Redux/actions/auth'

import Component from './Login'

const actions = {
  onSignIn: signIn,
  onSendResetPasswordCode: sendResetPasswordCode,
}

const selector = createStructuredSelector({})

export default connect(
  selector,
  actions,
)(Component)
