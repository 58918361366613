import React from 'react'
import { Route } from 'react-router-dom'

import Grid from '@material-ui/core/Grid'

const AuthenticationLayout = ({ children }) => (
  <Grid
    container
    display="flex"
    spacing={0}
    direction="column"
    alignItems="center"
    justify="center"
    style={{ minHeight: '100vh' }}
  >
    <Grid item xs={8} md={4} lg={3} xl={2}>
      {children}
    </Grid>
  </Grid>
)

const AuthenticationLayoutRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={matchProps => (
        <AuthenticationLayout>
          <Component {...matchProps} />
        </AuthenticationLayout>
      )}
    />
  )
}

export default AuthenticationLayoutRoute
