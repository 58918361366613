import React, { useEffect, useState } from 'react'
import MuiThemeProvider from '@material-ui/styles/ThemeProvider'

import { Provider } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Routes from './Components/Routes'

import { store, rehydrateStore } from './Redux'
import { subscribeAuthChange } from './Redux/actions/auth'

import Theme from './Themes/Theme'

import initFirebase from './Services/initFirebase'

const App = () => {
  const [isAppReady, setAppReady] = useState(false)

  useEffect(() => {
    const initApp = async () => {
      initFirebase()
      await rehydrateStore()
      subscribeAuthChange(store.dispatch)

      setAppReady(true)
    }

    initApp()
  }, [])

  return (
    <Provider store={store}>
      <MuiThemeProvider theme={Theme}>
        <Routes isAppReady={isAppReady} />
      </MuiThemeProvider>
      <ToastContainer />
    </Provider>
  )
}

export default App
