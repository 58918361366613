import React from 'react'

import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { getUserAuthenticated } from 'Redux/selectors/auth'
import { getUser } from 'Redux/selectors/user'

import * as routes from '../Constants/routes'

import AppLayoutRoute from './Layouts/AppLayout'
import AuthenticationLayoutRoute from './Layouts/AuthenticationLayout'

import Login from './Pages/Login'
import SplashScreen from './Pages/SplashScreen'

import FakeScreen from './Pages/FakeScreen'
import PackagesDeliveredChartPage from './Pages/PackagesDeliveredChart'
import TrackCodesChartPage from './Pages/TrackCodesChart'

const Routes = ({ isAuthenticated, user, isAppReady }) => {
  const getSwitch = () => {
    if (!isAppReady) {
      return <SplashScreen />
    }

    if (!isAuthenticated || !user) {
      return (
        <>
          <AuthenticationLayoutRoute
            exact
            path={routes.login}
            component={Login}
          />
        </>
      )
    }

    return (
      <>
        <Route exact path={routes.home}>
          <AppLayoutRoute component={FakeScreen} />
        </Route>
        <Route exact path={routes.packagesDeliveryChart}>
          <AppLayoutRoute component={PackagesDeliveredChartPage} />
        </Route>
        <Route exact path={routes.trackCodesChart}>
          <AppLayoutRoute component={TrackCodesChartPage} />
        </Route>
      </>
    )
  }

  return (
    <Router>
      <Switch>{getSwitch()}</Switch>
    </Router>
  )
}

const actions = {}

const selector = createStructuredSelector({
  isAuthenticated: getUserAuthenticated,
  user: getUser,
})

export default connect(
  selector,
  actions,
)(Routes)
